import React from 'react';
import { Alert } from 'react-bootstrap';

import {
  CORRECT_MESSAGE,
  ERROR_MESSAGE,
  NO_ANSWER_MESSAGE,
} from '../constants';

export const BootstrapAlert = (props) => {
  const { state, correctAnswer } = props;
  let alert = {
    variant: '',
    message: '',
    show: false,
  };
  switch (state) {
    case 1:
      alert.variant = 'success';
      alert.show = true;
      alert.message = CORRECT_MESSAGE;
      break;
    case 2:
      alert.variant = 'danger';
      alert.show = true;
      alert.message = ERROR_MESSAGE + ' ' + correctAnswer;
      break;
    case 3:
      alert.variant = 'warning';
      alert.show = true;
      alert.message = NO_ANSWER_MESSAGE;
      break;

    default:
      break;
  }

  return (
    <>
      {!alert.show ? (
        <div className="notification"></div>
      ) : (
        <Alert
          variant={alert.variant}
          show={alert.show}
        >
          {alert.message}
        </Alert>
      )}
    </>
  );
};
