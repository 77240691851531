import React, { useState, useEffect } from "react";

export const Operation = (props) => {
  const { op1, op2, symbol, result, box, answer } = props;
  const [widthEmptyBox, setWidthEmptyBox] = useState();

  useEffect(() => {
    const width =
      answer.toString() === "" ? "75px" : answer.toString().length * 75 + "px";
    setWidthEmptyBox(width);
  }, [answer]);

  return (
    <section className="mb-5 d-flex justify-content-center text-formula">
      <div className="mx-3">
        {box === 1 ? (
          <div
            className="text-center empty-box"
            style={{ width: widthEmptyBox }}
          >
            <span>{answer}</span>
          </div>
        ) : (
          <span>{op1}</span>
        )}
      </div>
      <div className="mx-3">
        <span>{symbol}</span>
      </div>
      <div className="mx-3">
        {box === 2 ? (
          <div
            className="text-center empty-box"
            style={{ width: widthEmptyBox }}
          >
            <span>{answer}</span>
          </div>
        ) : (
          <span>{op2}</span>
        )}
      </div>
      <div className="mx-3">
        <span>=</span>
      </div>
      <div className="mx-3">
        {box === 3 ? (
          <div
            className="text-center empty-box"
            style={{ width: widthEmptyBox }}
          >
            <span>{answer}</span>
          </div>
        ) : (
          <span>{result}</span>
        )}
      </div>
    </section>
  );
};
