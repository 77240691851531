import React from 'react';

export const ProblemButton = (props) => {
  const { onClick, name } = props;
  return (
    <div className="card my-5 mx-3">
      <button
        type="button"
        className="btn btn-primary"
        style={{ height: '100px' }}
        onClick={onClick}
      >
        {name}
      </button>
    </div>
  );
};
