import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Header } from "../components/layouts/Header";
import { Calculator } from "../components/Calculator";
import { MarkHeader } from "../components/MarkHeader";
import { Operation } from "../components/Operation";
import { HighScore } from "../components/HighScore";
import { LastFiveScore } from "../components/LastFiveScore";
import { ScoreModal } from "../components/ScoreModal";
import { BootstrapAlert as Alert } from "../components/Alert";
import {
  SLIDER_MIN_VALUE,
  CORRECT_MESSAGE,
  ERROR_MESSAGE,
  NO_ANSWER_MESSAGE,
  PLUS,
  MINUS,
  MULTIPLY_BY,
  DIVIDE_BY,
  MYSTERY_PLUS_OP1,
  MYSTERY_PLUS_OP2,
  MYSTERY_MINUS_OP1,
  MYSTERY_MINUS_OP2,
  MYSTERY_MULTIPLY_BY_OP1,
  MYSTERY_MULTIPLY_BY_OP2,
  MYSTERY_DIVIDE_BY_OP1,
  MYSTERY_DIVIDE_BY_OP2,
  EQUAL,
  TTS_API_KEY,
  LANGUAGE,
} from "../constants";
import { SET_PROBLEM_ID } from "../store/actions/types";

export const CalculatorPage = () => {
  const numberOfProblems = useSelector((state) => state.math.numberOfProblems);
  const maxValue = useSelector((state) => state.math.maxValue);
  const speech = useSelector((state) => state.math.speech);
  const problemId = useSelector((state) => state.math.problemId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [formula, setFormula] = useState({
    op1: "",
    op2: "",
    symbol: "",
    result: "",
    answer: "",
    text: "",
  });
  const [correctCount, setCorrectCount] = useState(0);
  const [solvedCount, setSolvedCount] = useState(1);
  const [wrongCount, setWrongCount] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [checkClicked, setCheckClicked] = useState(false);
  const [answer, setAnswer] = useState("");
  const [answerState, setAnswerState] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [scoreArray, setScoreArray] = useState([]);
  const [startTime, setStartTime] = useState(Date.now() / 1000);
  const [endTime, setEndTime] = useState(Date.now() / 1000);
  const [speechSrc, setSpeechSrc] = useState(false);
  const [prevValue1, setPrevValue1] = useState(0);
  const [prevValue2, setPrevValue2] = useState(0);
  const delayTime = speech.state ? 4000 : 2000;

  const readText = (text) => {
    if (speech.state) {
      const audioSrc = `http://api.voicerss.org/?key=${TTS_API_KEY}&hl=${LANGUAGE}&src=${text}&r=${speech.speed}`;
      setSpeechSrc(audioSrc);
    }
  };

  const setFormulaItems = () => {
    let op1, op2, symbol, result, answer, value1, value2, text;
    switch (problemId) {
      case 1: // addition
        op1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.add, prevValue1);
        setPrevValue1(op1);
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.add, prevValue2);
        setPrevValue2(op2);
        symbol = "+";
        result = op1 + op2;
        answer = 3;
        text = op1 + " " + PLUS + " " + op2;
        break;
      case 2: // subtraction
        value1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.sub, prevValue1);
        setPrevValue1(value1);
        value2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.sub, prevValue2);
        setPrevValue2(value2);
        op1 = value1 >= value2 ? value1 : value2;
        op2 = value1 >= value2 ? value2 : value1;
        symbol = "-";
        result = op1 - op2;
        answer = 3;
        text = op1 + " " + MINUS + " " + op2;
        break;
      case 3: // multiplication
        op1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue1);
        setPrevValue1(op1);
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue2);
        setPrevValue2(op2);
        symbol = "X";
        result = op1 * op2;
        answer = 3;
        text = op1 + " " + MULTIPLY_BY + " " + op2;
        break;
      case 4: // division
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue1);
        setPrevValue1(op2);
        result = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue2);
        setPrevValue2(result);
        symbol = "/";
        op1 = op2 * result;
        answer = 3;
        text = op1 + " " + DIVIDE_BY + " " + op2;
        break;
      case 5: // mystery additon
        op1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.add, prevValue1);
        setPrevValue1(op1);
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.add, prevValue2);
        setPrevValue2(op2);
        symbol = "+";
        result = op1 + op2;
        answer = getRandomInt(1, 2);
        text =
          answer === 1
            ? MYSTERY_PLUS_OP1 + " " + op2 + " " + EQUAL + " " + result
            : op1 + " " + MYSTERY_PLUS_OP2 + " " + EQUAL + " " + result;
        break;
      case 6: // mystery subtraction
        value1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.sub, prevValue1);
        setPrevValue1(value1);
        value2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.sub, prevValue2);
        setPrevValue2(value2);
        op1 = value1 >= value2 ? value1 : value2;
        op2 = value1 >= value2 ? value2 : value1;
        symbol = "-";
        result = op1 - op2;
        answer = getRandomInt(1, 2);
        text =
          answer === 1
            ? MYSTERY_MINUS_OP1 + " " + op2 + " " + EQUAL + " " + result
            : op1 + " " + MYSTERY_MINUS_OP2 + " " + EQUAL + " " + result;
        break;
      case 7: // mystery multiplication
        op1 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue1);
        setPrevValue1(op1);
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue2);
        setPrevValue2(op2);
        symbol = "X";
        result = op1 * op2;
        answer = getRandomInt(1, 2);
        text =
          answer === 1
            ? MYSTERY_MULTIPLY_BY_OP1 + " " + op2 + " " + EQUAL + " " + result
            : op1 + " " + MYSTERY_MULTIPLY_BY_OP2 + " " + EQUAL + " " + result;
        break;
      case 8: // mystery division
        result = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue1);
        setPrevValue1(result);
        op2 = getRandomInt(SLIDER_MIN_VALUE, maxValue.multiDiv, prevValue2);
        setPrevValue2(op2);
        symbol = "/";
        op1 = op2 * result;
        answer = getRandomInt(1, 2);
        text =
          answer === 1
            ? MYSTERY_DIVIDE_BY_OP1 + " " + op2 + " " + EQUAL + " " + result
            : op1 + " " + MYSTERY_DIVIDE_BY_OP2 + " " + EQUAL + " " + result;
        break;
      default:
        break;
    }

    // formula
    readText(text);

    return {
      op1: op1,
      op2: op2,
      symbol: symbol,
      result: result,
      answer: answer, // 1: op1, 2: op2, 3: result
      text: text,
    };
  };

  const getRandomInt = (min, max, prevValue = 0) => {
    if (prevValue === 0) {
      return Math.floor(Math.random() * max) + min;
    } else {
      while (1) {
        const value = Math.floor(Math.random() * max) + min;
        if (value !== prevValue) {
          return value;
        }
      }
    }
  };

  const handleCheck = () => {
    if (answer !== "") {
      setCheckClicked(true);
    } else {
      readText(NO_ANSWER_MESSAGE);
      setAnswerState(3);
      setTimeout(() => {
        setAnswerState(4);
      }, delayTime);
    }
  };

  const delay = () => {
    setTimeout(() => {
      setCheckClicked(false);
      setAnswer("");
      setAnswerState(0);
      setSolvedCount(solvedCount + 1);
      setFormula(setFormulaItems());
    }, delayTime);
  };

  const delayShowingModal = () => {
    setTimeout(() => {
      setModalVisible(true);
    }, 1000);
  };

  const setCount = () => {
    let correctValue;
    if (formula.answer === 1) {
      correctValue = formula.op1;
    } else if (formula.answer === 2) {
      correctValue = formula.op2;
    } else {
      correctValue = formula.result;
    }
    setCorrectAnswer(correctValue);
    if (answer === correctValue && checkClicked) {
      readText(CORRECT_MESSAGE);
      setAnswerState(1);
      setCorrectCount(correctCount + 1);
    } else if (answer !== "" && checkClicked) {
      readText(ERROR_MESSAGE + " " + correctValue);
      setAnswerState(2);
      setWrongCount(wrongCount + 1);
    }
  };

  const handleStartOver = () => {
    setScoreArray([
      ...scoreArray,
      Math.ceil((correctCount / (endTime - startTime)) * 1000),
    ]);
    setModalVisible(false);
    setStartTime(Date.now() / 1000);
    setEndTime(Date.now() / 1000);
    setCheckClicked(false);
    setCorrectAnswer(0);
    setCorrectCount(0);
    setWrongCount(0);
    setSolvedCount(1);
    setAnswerState(4);
    setAnswer("");
    setFormula(setFormulaItems());
  };

  const handleExitGame = () => {
    dispatch({ type: SET_PROBLEM_ID, payload: 0 });
    setModalVisible(false);
    history.go("-1");
  };

  useEffect(() => {
    if (problemId !== 0) {
      // reset the formula
      setFormula(setFormulaItems());
    } else {
      history.go("-1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkClicked) {
      setCount();
      if (solvedCount <= numberOfProblems - 1) {
        delay();
      } else {
        setEndTime(Date.now() / 1000);
        delayShowingModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkClicked]);

  return (
    <>
      <Header setting={false} page={1} />
      <MarkHeader
        solvedCount={solvedCount}
        numberOfProblems={numberOfProblems}
        correctCount={correctCount}
        wrongCount={wrongCount}
      />
      <Operation
        op1={formula.op1}
        op2={formula.op2}
        symbol={formula.symbol}
        result={formula.result}
        box={formula.answer}
        answer={answer}
      />
      <div className="container">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 text-center">
            <Alert state={answerState} correctAnswer={correctAnswer} />
            <button
              className="btn btn-primary btn-lg pt-3 pr-5 pb-3 pl-5"
              onClick={handleCheck}
            >
              Check
            </button>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <div className="album py-5">
        <div className="container">
          <div className="row">
            <div className="col-4 text-left">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-8 text-left">
                  <HighScore scoreArray={scoreArray} />
                </div>
                <div className="col-1"></div>
              </div>
            </div>
            <div className="col-4">
              <Calculator
                answer={answer}
                setAnswer={setAnswer}
                setSpeechSrc={setSpeechSrc}
              />
            </div>
            <div className="col-4 text-right">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8 text-left mr-1">
                  <LastFiveScore scoreArray={scoreArray} />
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScoreModal
        modalVisible={modalVisible}
        correctCount={correctCount}
        wrongCount={wrongCount}
        endTime={endTime}
        startTime={startTime}
        handleStartOver={handleStartOver}
        handleExitGame={handleExitGame}
      />
      {speechSrc && <audio autoPlay src={speechSrc}></audio>}
    </>
  );
};
