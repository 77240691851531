import React from "react";
import Switch from "react-switch";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useSelector, useDispatch } from "react-redux";

import {
  SLIDER_MIN_VALUE,
  SLIDER_MAX_VALUE,
  SPEECH_MIN_VALUE,
  SPEECH_MAX_VALUE,
} from "../constants";
import {
  SET_PROBLEM_COUNT,
  SET_MAX_VALUE,
  SET_SPEECH,
} from "../store/actions/types";

export const Setting = (props) => {
  const { showSetting, setShowReadMe } = props;
  const dispatch = useDispatch();
  const numberOfProblems = useSelector((state) => state.math.numberOfProblems);
  const maxValue = useSelector((state) => state.math.maxValue);
  const speech = useSelector((state) => state.math.speech);

  const toggleSwitch = () => {
    dispatch({
      type: SET_SPEECH,
      payload: { ...speech, state: !speech.state },
    });
  };

  const handleReset = () => {
    dispatch({ type: null, payload: null });
  };

  const handleReadMe = () => {
    showSetting(false);
    setShowReadMe(true);
  };

  const handleChangeValue = (val, id) => {
    switch (id) {
      case 1:
        dispatch({ type: SET_PROBLEM_COUNT, payload: val });
        break;
      case 2:
        dispatch({ type: SET_MAX_VALUE, payload: { ...maxValue, add: val } });
        break;
      case 3:
        dispatch({ type: SET_MAX_VALUE, payload: { ...maxValue, sub: val } });
        break;
      case 4:
        dispatch({
          type: SET_MAX_VALUE,
          payload: { ...maxValue, multiDiv: val },
        });
        break;
      case 5:
        dispatch({ type: SET_SPEECH, payload: { ...speech, speed: val } });
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-white shadow rounded setting-popup">
      <div className="container">
        <div className="row pt-2">
          <div className="col-4 pt-1">
            <i
              className="fa fa-info-circle fa-lg setting-reset"
              onClick={handleReadMe}
            />
          </div>
          <div className="col-4 text-center font-weight-bold">
            <span className="setting-header">Settings</span>
          </div>
          <div className="col-4 text-right pt-1">
            <span onClick={handleReset} className="setting-reset">
              Reset
            </span>
          </div>
        </div>
        <hr className="ml-n3 mt-2 setting-popup" />
        <div>
          <div className="row">
            <div className="col-6">
              <span>Sound Off</span>
            </div>
            <div className="col-6 text-right">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#007bff"
                height={20}
                width={40}
                onChange={toggleSwitch}
                checked={speech.state}
              />
            </div>
          </div>
          <div className="mt-3">
            <span>Number Of Problems</span>
            <div className="row">
              <div className="col-10">
                <RangeSlider
                  value={numberOfProblems}
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  tooltip="off"
                  onChange={(changeEvent) =>
                    handleChangeValue(changeEvent.target.value, 1)
                  }
                />
              </div>
              <div className="col-2 setting-range-value">
                <span>{numberOfProblems}</span>
              </div>
            </div>
          </div>
          <div>
            <span>Addition</span>
            <div className="row">
              <div className="col-10">
                <RangeSlider
                  value={maxValue.add}
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  tooltip="off"
                  onChange={(changeEvent) =>
                    handleChangeValue(changeEvent.target.value, 2)
                  }
                />
              </div>
              <div className="col-2 setting-range-value">
                <span>{maxValue.add}</span>
              </div>
            </div>
          </div>
          <div>
            <span>Subtraction</span>
            <div className="row">
              <div className="col-10">
                <RangeSlider
                  value={maxValue.sub}
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  tooltip="off"
                  onChange={(changeEvent) =>
                    handleChangeValue(changeEvent.target.value, 3)
                  }
                />
              </div>
              <div className="col-2 setting-range-value">
                <span>{maxValue.sub}</span>
              </div>
            </div>
          </div>
          <div>
            <span>Multiplication/Division</span>
            <div className="row">
              <div className="col-10">
                <RangeSlider
                  value={maxValue.multiDiv}
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  tooltip="off"
                  onChange={(changeEvent) =>
                    handleChangeValue(changeEvent.target.value, 4)
                  }
                />
              </div>
              <div className="col-2 setting-range-value">
                <span>{maxValue.multiDiv}</span>
              </div>
            </div>
          </div>
          <div>
            <span>Speech speed</span>
            <div className="row">
              <div className="col-10">
                <RangeSlider
                  value={speech.speed}
                  min={SPEECH_MIN_VALUE}
                  max={SPEECH_MAX_VALUE}
                  tooltip="off"
                  onChange={(changeEvent) =>
                    handleChangeValue(changeEvent.target.value, 5)
                  }
                />
              </div>
              <div className="col-2 setting-range-value">
                <span>{speech.speed}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center setting-version">
          <p className="pt-4">© Archer Development</p>
          <p className="mt-n3 pb-4">Version 1.2.2</p>
        </div>
      </div>
    </div>
  );
};
