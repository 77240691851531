export const SLIDER_MIN_VALUE = 1;
export const SLIDER_MAX_VALUE = 100;
export const SPEECH_MIN_VALUE = 1;
export const SPEECH_MAX_VALUE = 6;
export const CORRECT_MESSAGE = 'Correct';
export const ERROR_MESSAGE = 'Sorry the answer is';
export const NO_ANSWER_MESSAGE = 'Please type answer first';
export const PLUS = 'plus';
export const MINUS = 'minus';
export const MULTIPLY_BY = 'multiply by';
export const DIVIDE_BY = 'divide by';
export const MYSTERY_PLUS_OP1 = 'What plus';
export const MYSTERY_PLUS_OP2 = 'plus What';
export const MYSTERY_MINUS_OP1 = 'What minus';
export const MYSTERY_MINUS_OP2 = 'minus What';
export const MYSTERY_MULTIPLY_BY_OP1 = 'What multiply by';
export const MYSTERY_MULTIPLY_BY_OP2 = 'multiply by What';
export const MYSTERY_DIVIDE_BY_OP1 = 'What divided by';
export const MYSTERY_DIVIDE_BY_OP2 = 'divided by What';
export const EQUAL = 'equals';
export const TTS_API_KEY = '332c16d1a8db4a10b44047fd0888b485';
export const LANGUAGE = 'en-us';
