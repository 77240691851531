import React from 'react';
import { Modal } from 'react-bootstrap';

export const ReadMeModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6 text-center">
              <span className="readme">README</span>
            </div>
            <div className="col-3 text-right">
              <span onClick={props.onHide}>
                <i className="fa fa-close fa-lg pt-3" />
              </span>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          The Mathematical Challenges game is the first in a series of games to
          provide practice in basic math operations. This game provides practice
          in addition, subtraction, multiplication and division. The student can
          both see and hear each problem. Erroneous answers will be corrected
          both visually and aurally.
        </p>
        <p>
          The players will be asked to solve a set of problems. Each set will be
          timed. The goal of the game is for the player to reduce the time to
          solve the set of problems with no errors. The numbers in each
          successive set will be randomly selected within operator defined
          boundaries. A score is provided for each set of problems based on the
          number of correct answers and the time to complete the set. The scores
          are cleared when a new game or setting is selected.
        </p>
        <p>
          Settings are provided to turn the speaker ON or OFF, to control the
          speed of the speaker, to select the number of problems in a set of
          problems, and to select the range of the whole numbers to be randomly
          used in the set of problems. Default selections are Sound ON, speech
          speed 2, 10 problems in a set, and the range of whole numbers from 0
          to 10 for addition, subtraction, multiplication, and division.
        </p>
        <p>
          Mystery math games are also provided for practice in determining the
          unknown whole number in an equation relating three whole numbers.
        </p>
        <p>
          The game is designed to support a subset of the Operations and
          Algebraic Thinking skills required in the Common Core State Standards
          for Grades 1, 2, and 3. The game provides remedial practice for Grades
          4, 5, and 6.
        </p>
        <p>
          With a focus on self-improvement, the games develop mental arithmetic
          skills as the players race the clock to reduce the time they take to
          solve a set of problems.
        </p>
      </Modal.Body>
    </Modal>
  );
};
