import React from 'react';

export const HighScore = ({ scoreArray }) => {
  return (
    <div>
      <p className="score">High Score</p>
      <p>{scoreArray.length > 0 ? Math.max(...scoreArray) : ''}</p>
    </div>
  );
};
