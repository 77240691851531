import React from "react";

export const CalculatorButton = (props) => {
  const { name, onClick } = props;
  return (
    <div className="my-1 text-center">
      <button type="button" className="btn calcalator-button" onClick={onClick}>
        {name}
      </button>
    </div>
  );
};
