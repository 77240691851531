/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { Setting } from "../Setting";
import { ReadMeModal } from "../ReadMeModal";

export const Header = (props) => {
  const { setting, showSetting, page } = props;
  const [showReadMe, setShowReadMe] = useState(false);
  const history = useHistory();

  const handleHide = () => {
    setShowReadMe(false);
    showSetting(true);
  };

  const handleLogoClick = () => {
    if (page !== 0) {
      history.go("-1");
    }
  };

  return (
    <header>
      <div className="navbar header">
        <div className="container">
          <div className="row w-100">
            <div className="col-md-8 offset-md-2 text-center">
              <a
                className="title align-items-center logo"
                onClick={handleLogoClick}
              >
                Mathematical Challenges
              </a>
            </div>
            <div className="col-md-2 text-right pt-3">
              {setting && (
                <Popup
                  trigger={
                    <button className="navbar-toggler" type="button">
                      <i className="fa fa-cog" />
                    </button>
                  }
                  position="bottom right"
                >
                  <Setting
                    showSetting={showSetting}
                    showReadMe={showReadMe}
                    setShowReadMe={setShowReadMe}
                  />
                </Popup>
              )}
              {showReadMe && (
                <button className="navbar-toggler" type="button">
                  <i className="fa fa-cog" />
                </button>
              )}
            </div>
          </div>
          <ReadMeModal show={showReadMe} onHide={handleHide} />
        </div>
      </div>
    </header>
  );
};
