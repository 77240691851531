import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ProblemButton } from "../components/ProblemButton";
import { SET_PROBLEM_ID } from "../store/actions/types";

export const ProblemGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (id) => {
    dispatch({ type: SET_PROBLEM_ID, payload: id });
    history.push("/calculator");
  };

  return (
    <div className="album py-5 my-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <ProblemButton name="ADDITION" onClick={() => handleClick(1)} />
          </div>
          <div className="col-md-3">
            <ProblemButton name="SUBTRACTION" onClick={() => handleClick(2)} />
          </div>
          <div className="col-md-3">
            <ProblemButton
              name="MULTIPLICATION"
              onClick={() => handleClick(3)}
            />
          </div>
          <div className="col-md-3">
            <ProblemButton name="DIVISION" onClick={() => handleClick(4)} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <ProblemButton
              name="MYSTERY ADDITION"
              onClick={() => handleClick(5)}
            />
          </div>
          <div className="col-md-3">
            <ProblemButton
              name="MYSTERY SUBSTRACTION"
              onClick={() => handleClick(6)}
            />
          </div>
          <div className="col-md-3">
            <ProblemButton
              name="MYSTERY MULTIPLICATION"
              onClick={() => handleClick(7)}
            />
          </div>
          <div className="col-md-3">
            <ProblemButton
              name="MYSTERY DIVISION"
              onClick={() => handleClick(8)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
