import React from 'react';
import { Modal } from 'react-bootstrap';

export const ScoreModal = (props) => {
  const {
    modalVisible,
    correctCount,
    wrongCount,
    endTime,
    startTime,
    handleStartOver,
    handleExitGame,
  } = props;

  const sec2Time = (sec) => {
    const pad = (num, size) => {
      return ('000' + num).slice(size * -1);
    };
    const time = parseFloat(sec).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);
    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
  };

  return (
    <Modal show={modalVisible} className="text-center" centered>
      <Modal.Body
        className="text-center pt-4"
        style={{ backgroundColor: '#222933' }}
      >
        <div className="mb-3">
          <i
            className="fa fa-exclamation-circle"
            style={{ color: '#fdb416', fontSize: 60 }}
          />
        </div>
        <h6 className="text-white mb-4">
          How many correct Answers: {correctCount}
        </h6>
        <h6 className="text-white mb-4">
          How many missed Answers: {wrongCount}
        </h6>
        <h6 className="text-white mb-4">
          {' '}
          Your time: {sec2Time(endTime - startTime)}
        </h6>
        <div>
          <button
            className="btn btn-primary mb-3 mx-2"
            onClick={handleStartOver}
          >
            START OVER
          </button>
          <button className="btn btn-danger mb-3 mx-2" onClick={handleExitGame}>
            EXIT GAME
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
