import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '../node_modules/font-awesome/scss/font-awesome.scss';

import { HomePage } from './pages/HomePage';
import { CalculatorPage } from './pages/CalculatorPage';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/calculator">
            <CalculatorPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
