import React from 'react';

export const Footer = () => {
  return (
    <footer className="text-muted">
      <div className="container">
        <p className="text-center">Ages 4+, Made for ages 6-8, Remedial Practice for ages 9-12</p>
      </div>
    </footer>
  );
};
