import React from 'react';
import { CalculatorButton } from './CalculatorButton';

export const Calculator = (props) => {
  const { answer, setAnswer, setSpeechSrc } = props;

  const handleClick = (value) => {
    setSpeechSrc(false);
    if (value !== 'C') {
      setAnswer(answer * 10 + value);
    } else {
      setAnswer('');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <CalculatorButton name="1" onClick={() => handleClick(1)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="2" onClick={() => handleClick(2)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="3" onClick={() => handleClick(3)} />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CalculatorButton name="4" onClick={() => handleClick(4)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="5" onClick={() => handleClick(5)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="6" onClick={() => handleClick(6)} />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CalculatorButton name="7" onClick={() => handleClick(7)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="8" onClick={() => handleClick(8)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="9" onClick={() => handleClick(9)} />
        </div>
      </div>
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <CalculatorButton name="0" onClick={() => handleClick(0)} />
        </div>
        <div className="col-4">
          <CalculatorButton name="C" onClick={() => handleClick('C')} />
        </div>
      </div>
    </>
  );
};
