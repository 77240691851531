import React from 'react';

export const LastFiveScore = ({ scoreArray }) => {
  return (
    <div>
      <p className="score">Last 5 Scores</p>
      <div className="text-left">
        {scoreArray.length > 0 &&
          scoreArray.slice(-5).map((score, index) => (
            <p>
              {index + 1}. {score}
            </p>
          ))}
      </div>
    </div>
  );
};
