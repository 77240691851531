import React from 'react';

export const MarkHeader = (props) => {
  const { solvedCount, numberOfProblems, correctCount, wrongCount } = props;

  return (
    <section className="my-4 text-problem-count">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <span>
              Problem: {solvedCount} / {numberOfProblems}
            </span>
          </div>
          <div className="col-6 text-right">
            <i className="fa fa-check mx-2 text-success" />
            <span className="mx-2">{correctCount}</span>
            <i className="fa fa-close mx-2 text-danger" />
            <span className="ml-2">{wrongCount}</span>
          </div>
        </div>
      </div>
    </section>
  );
};
