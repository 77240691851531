import React, { useState } from "react";

import { Header } from "../components/layouts/Header";
import { Footer } from "../components/layouts/Footer";
import { ProblemGroup } from "../components/ProblemGroup";
// import { JumboTron } from "../components/layouts/JumboTron";

export const HomePage = () => {
  const [setting, showSetting] = useState(true);

  return (
    <>
      <Header setting={setting} showSetting={showSetting} page={0} />
      {/* <JumboTron /> */}
      <ProblemGroup />
      <Footer />
    </>
  );
};
